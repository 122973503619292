<template>
<div>
  <DataCenterView :sidebar-width="265" :class="{'opacity-50 pointer-events-none': loadingListData}">
    <!-- Top bar -->
    <template slot="top-bar">
      <div class="flex items-center ml-auto">
        <IconLink
          :disabled="!columnIsSelected"
          :loading="loadingCsvExport"
          @click="$store.dispatch('getCsvExport')"
          icon-name="IconExportCSV"
          customIcon
        />
        <IconLink
          class="ml-5"
          :disabled="!columnIsSelected"
          :loading="loadingXlsxExport"
          @click="$store.dispatch('getXlsxExport')"
          icon-name="IconExportXLSX"
          customIcon
        />
        <IconLink
          class="ml-5"
          :disabled="!populationIsSelected"
          @click="toggleResetListModal()"
          icon-name="trash-can-outline"
        />
      </div>
    </template>
    <!-- Sidebar -->
    <template slot="sidebar">
      <div class="flex flex-col h-full">
        <SidebarTile class="p-3 mb-3">
          <PopulationSelect />
        </SidebarTile>
        <SidebarTile class="flex-grow mb-3">
          <Filters @updated-filters="refreshList" />
        </SidebarTile>
        <SidebarTile class="p-3">
          <ItemCount />
        </SidebarTile>
      </div>
    </template>
    <!-- Content -->
    <template slot="content">
      <div class="relative h-full bg-white">
        <ColumnSelectField @updated-columns="refreshList" class="border-gray-200 border-b-11" />
        <!-- Debug screen -->
        <div class="text-xs" v-if="debugActive">
          <div class="font-bold ">DEBUG MODE (press Windows + &lt; to exit)</div>
          <div class="pl-1">{{ $store.getters.getListLength }}</div>
          <div v-if="selCols.length > 0">
            <div class="pl-1">selected columns:</div>
            <div class="pl-1">{{ selCols }}</div>
          </div>
          <div v-if="selFilters.length > 0">
            <div class="pl-1">selected filters:</div>
            <div class="pl-1">{{ selFilters }}</div>
          </div>
        </div>
        <!-- Table -->
        <div
          ref="table"
          v-if="showTable"
          class="overflow-auto text-gray-800 datacenter__table-list table-height"
          @scroll="onTableScroll"
        >
          <Table :items="rowData" :columns="columnLabels" smallSpacing />
        </div>
        <div v-else class="flex flex-col items-center justify-center h-full">
          <EmptyState />
        </div>
        <LoadingCircle
          v-show="loadingListData"
          centered
          :class="{ 'transform -translate-y-20': !showTable }"
        />
      </div>
    </template>
  </DataCenterView>
    <DialogModal
      v-model="resetListModal"
      :primary-text="$t('reallyWantToResetList')"
      :secondary-text="$t('unsavedDataIsLost')"
      @cancel="toggleResetListModal()"
      @approve="resetList()"
    />
</div>
</template>

<script>
import EmptyState from '@/ui/views/DataCenter/DataCenterList/components/EmptyState.vue';
import IconLink from '../../../components/IconLink.vue';
import DataCenterView from '../components/DataCenterView.vue';
import SidebarTile from './components/SidebarTile.vue';
import PopulationSelect from './components/PopulationSelect.vue';
import Filters from './components/Filters.vue';
import ItemCount from './components/ItemCount.vue';
import ColumnSelectField from './components/SelectedColumns.vue';
import LoadingCircle from '../../../components/LoadingCircle.vue';
import DialogModal from '../../../components/DialogModal.vue';

export default {
  components: {
    EmptyState,
    ItemCount,
    DialogModal,
    Filters,
    PopulationSelect,
    SidebarTile,
    DataCenterView,
    ColumnSelectField,
    IconLink,
    LoadingCircle,
  },
  data() {
    return {
      debugActive: false,
      resetListModal: false,
      lastHeight: 0,
    };
  },
  computed: {
    selCols() {
      return this.$store.getters.getSelCols;
    },
    selFilters() {
      return this.$store.getters.getSelFilters;
    },
    populationIsSelected() {
      return this.$store.getters.getSelPopulation?.length > 0;
    },
    columnIsSelected() {
      return this.selCols.length > 0;
    },
    loadingCsvExport() {
      return this.$store.state.datacenterList.loadingCsvExport;
    },
    loadingXlsxExport() {
      return this.$store.state.datacenterList.loadingXlsxExport;
    },
    loadingListData() {
      return this.$store.state.datacenterList.loadingListData;
    },
    rowData() {
      return this.$store.getters.getRowData;
    },
    showTable() {
      return this.rowData.length > 0;
    },
    columnLabels() {
      return Object.keys(this.rowData[0]).map((e) => ({
        label: this.$tc(e, 1),
        sortable: false,
        sortValue: e,
        width: 'w-1/6',
      }));
    },
  },
  methods: {
    clearRows() {
      this.$store.commit('updateRows', []);
    },
    scrollToTop() {
      if (this.$refs.table) {
        this.$refs.table.scrollTop = 0;
      }
    },
    updateList() {
      this.$store.dispatch('getChunk');
    },
    refreshList() {
      // refresh complete list when filter or column configuration has changed
      this.scrollToTop();
      this.lastHeight = 0;
      if (this.selCols.length === 0) {
        this.clearRows();
      } else {
        this.$store.dispatch('getChunk', 1);
      }
    },
    toggleDebug() {
      this.debugActive = !this.debugActive;
    },
    hasUpdateScrollHeight(evt, updateThreshold) {
      const isScrolled = evt.target.scrollHeight - evt.target.scrollTop;
      const onHeightUpdated = this.lastHeight !== evt.target.scrollHeight;
      return isScrolled < updateThreshold && (onHeightUpdated || !this.lastHeight);
    },
    onTableScroll(evt) {
      if (this.hasUpdateScrollHeight(evt, 3000)) {
        this.updateList();
        this.lastHeight = evt.target.scrollHeight;
      }
    },
    resetList() {
      this.$store.dispatch('resetDatacenterList');
      this.toggleResetListModal();
    },
    toggleResetListModal() {
      this.resetListModal = !this.resetListModal;
    },
  },
  mounted() {
    window.addEventListener('keyup', (evt) => {
      if (evt.getModifierState('OS')) {
        if (evt.key === '<') {
          this.toggleDebug();
        }
      }
    });
  },
};
</script>

<style lang="scss">
.datacenter__table-list {
  &.table-height {
    max-height: 65.7vh;
  }

  .table-cell {
    white-space: nowrap;
  }
}
</style>
