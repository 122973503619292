<template>
  <FilterElem :name="$tc(filter.type, 1)" :active="hasSelectedValues">
    <div
      class="block text-sm text-right text-gray-300 transition duration-100 pointer-events-none"
      :class="{'pointer-events-auto cursor-pointer text-gray-800 hover:text-primary-500': hasSelectedValues}"
      @click="deselectAll"
    >
      {{ $t('remove_selection') }}
    </div>
    <DatePicker
      v-model="selectedValues"
      @input="onInput"
      @dateValid="onDateValid"
      @click.native="onClick"
      :min="minDate"
      :max="maxDate"
      panel-class="left-0"
      placeholder="TT.MM.JJJJ - TT.MM.JJJJ"
      icon="calendar-multiselect"
      period
      year-select
    />
  </FilterElem>
</template>

<script>
import { addYears, format, subYears } from 'date-fns';
import FilterElem from './FilterElem.vue';

export default {
  components: { FilterElem },
  data() {
    return {
      selectedValues: null,
    };
  },
  props: {
    /**
     * Value is an array of selected filters in the following form:
     * [
     *   {
     *     id: ...,
     *     values: [...]
     *   },
     *   ...
     * ]
     */
    value: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.selectedValues = this.value.find((f) => f.type === this.filter.type)?.values ?? null;
  },
  computed: {
    hasSelectedValues() {
      return this.selectedValues !== null;
    },
    maxDate() {
      if (this.filter.noFuture) {
        return format(new Date(), 'yyyy-MM-dd');
      }
      return format(addYears(new Date(), 20), 'yyyy-MM-dd');
    },
    minDate() {
      return format(subYears(new Date(), 100), 'yyyy-MM-dd');
    },
  },
  methods: {
    onDateValid(values) {
      this.$emit('input', { type: this.filter.type, values });
    },
    deselectAll() {
      this.selectedValues = null;
      this.$emit('input', { type: this.filter.type, values: [] });
    },
    onClick() {
      this.$emit('click-input');
    },
    onInput(val) {
      if (val === null) {
        // User has cleared input field with backspace
        this.deselectAll();
      }
    },
  },
};
</script>

<style scoped>
.checkbox {
  margin-bottom: .5rem !important;
}
</style>
