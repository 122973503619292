<template>
  <div class="flex flex-col bg-gray-200 min-h-screen-action-bar">
    <!-- Top bar -->
    <div class="flex flex-row px-5 py-2 bg-white top-bar">
      <!-- Sidebar toggle button -->
      <IconLink
        class="transition-transform duration-200"
        :class="{'transform rotate-180': !sidebarActive}"
        @click="toggleSidebar" icon-name="backburger"
      />
      <!-- Slot content for top bar -->
      <slot name="top-bar"/>
    </div>
    <!-- Main view -->
    <div
      class="flex flex-grow w-full py-3 main"
      :class="{ 'sb-hidden': !sidebarActive }"
    >
      <!-- Sidebar -->
      <div class="overflow-y-hidden transition-all duration-200 sidebar" :style="styleSidebar">
        <slot name="sidebar"/>
      </div>
      <!-- Content -->
      <div class="flex-grow mx-3 overflow-hidden transition-all duration-200 content" :style="styleContent">
        <slot name="content"/>
      </div>
    </div>
  </div>
</template>

<script>
import IconLink from '../../../components/IconLink.vue';

export default {
  components: {
    IconLink,
  },
  data() {
    return {
      sidebarActive: true,
      debugActive: false,
      sampleDataSelected: false,
      sampleDataSelectedEmpty: false,
    };
  },
  props: {
    sidebarWidth: {
      required: false,
      type: Number,
      default: 550,
    },
  },
  computed: {
    styleSidebar() {
      const style = { width: `${this.sidebarWidth}px` };
      if (this.sidebarActive) style.left = 0;
      else style.left = `${-this.sidebarWidth}px`;
      return style;
    },
    styleContent() {
      if (this.sidebarActive) {
        return { paddingLeft: `${this.sidebarWidth}px` };
      }
      return { paddingLeft: 0 };
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarActive = !this.sidebarActive;
    },
  },
};
</script>

<style scoped lang="scss">

.main {
  position: relative;
  .sidebar {
    position: absolute;
    top: 0.75rem;
    bottom: 0.75rem;
    left: 0;
  }
}
</style>
