<template>
  <div
    id="column-list"
    class="absolute z-10 bg-white border-2 border-gray-200 list-pos list-size"
    :class="{'hidden': !show}"
  >
    <div class="ml-1 text-gray-800 text">{{ $t('add_columns') }}</div>
    <!-- Search -->
    <div class="p-3 pb-0">
      <TextField v-model="searchFilter" :placeholder="$t('search')" class="text-gray-800 override-mb" searchfield/>
    </div>
    <!-- Buttons -->
    <div class="flex flex-row-reverse p-3 pt-0 text-sm text-gray-800 hover:text-primary-500">
      <button :class="{'text-gray-300 pointer-events-none': !selCols.length}"  @click="deselectAll" class="focus:outline-none">
        {{ $t('deselect_all') }}
      </button>
    </div>
    <!-- Checkboxes -->
    <div class="pb-3 overflow-y-auto checkbox-list-height">
      <div v-for="(colGroup, i) in filteredAvailableCols" :key="colGroup.group" class="px-3">
        <div v-if="colGroup">
          <FilterElem
            :name="$tc(colGroup.group, 1)"
            :active="groupIsActive(colGroup)"
            :class="{ 'border-b-0': i !== filteredAvailableCols.length - 1 }"
          >
            <div class="flex flex-row-reverse">
              <button
                class="text-sm text-gray-800 focus:outline-none hover:text-primary-500"
                @click="toggleColumnGroup(colGroup)"
              >
                <div v-if="allColumnsSelected(colGroup)"> {{ $t('unselect_all') }} </div>
                <div v-else> {{ $t('select_all') }} </div>
              </button>
              </div>
            <div v-for="col in colGroup.columns" :key="col + 'column'" class="pl-1">
              <CheckBox
                v-model="selCols"
                :native-value="col"
                class="pt-1 override-mb"
              >
                <div>{{ $tc(col, 1) }}</div>
              </CheckBox>
            </div>
          </FilterElem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterElem from './FilterElem.vue';

export default {
  components: { FilterElem },
  data() {
    return {
      searchFilter: null,
    };
  },
  props: {
    show: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availableCols() {
      return this.$store.getters.getAvailableCols;
    },
    selCols: {
      get() {
        return this.$store.getters.getSelCols;
      },
      set(values) {
        this.$store.commit('setSelCols', values);
      },
    },
    sortedAvailableCols() {
      return this.sortAvailableCols(this.availableCols);
    },
    // TODO: If something like that is necessary then it comes wrong from backend
    filteredAvailableCols() {
      if (this.searchFilter) {
        return this.sortedAvailableCols.map((group) => {
          const filteredValues = group.columns.filter((e) => {
            const valueToLower = this.$tc(e, 1).toLowerCase().trim(); // Use translation as filtered value
            const searchFilterToLower = this.searchFilter.toLowerCase().trim();
            return valueToLower.indexOf(searchFilterToLower) >= 0;
          });
          const filteredCategory = {
            group: group.group,
            columns: [...filteredValues],
          };
          if (filteredCategory.columns.length > 0) {
            return filteredCategory;
          }
          return null;
        }).filter((e) => e !== null);
      }
      return this.sortedAvailableCols;
    },
  },
  mounted() {
    window.addEventListener('click', this.clickHandler);
  },
  methods: {
    deselectAll() {
      this.selCols = [];
    },
    clickHandler(e) {
      if (!document.getElementById('column-list')?.contains(e.target) // ignore clicks on plus-button and searchfield-clear-button
        && e.target.id !== 'plus-button' && e.target.id !== 'searchfield-clear-button') {
        this.$emit('clicked-outside');
        e.target.removeEventListener('click', this.clickHandler);
      }
    },
    groupIsActive(groups) {
      const cols = groups.columns.filter((e) => this.selCols.includes(e));
      return cols.length > 0;
    },
    sortAvailableCols(groups) {
      return groups.map((group) => {
        const filteredValues = group.columns.sort((a, b) => {
          const aTranslated = this.$tc(a, 1);
          const bTranslated = this.$tc(b, 1);
          if (aTranslated === bTranslated) {
            return 0;
          }
          return aTranslated > bTranslated ? 1 : -1;
        });
        const filteredCategory = {
          group: group.group,
          columns: [...filteredValues],
        };
        if (filteredCategory.columns.length > 0) {
          return filteredCategory;
        }
        return null;
      });
    },
    allColumnsSelected(columnGroup) {
      return columnGroup.columns.every((column) => this.selCols.includes(column));
    },
    toggleColumnGroup(columnGroup) {
      this.selCols = this.allColumnsSelected(columnGroup) ? this.unselectGroup(columnGroup) : this.selectGroup(columnGroup);
    },
    unselectGroup(columnGroup) {
      return this.selCols.filter((column) => !columnGroup.columns.includes(column));
    },
    selectGroup(columnGroup) {
      return [...this.unselectGroup(columnGroup), ...columnGroup.columns];
    },
  },
};
</script>

<style lang="scss">

.list-pos {
  top: 60px;
  right: 26px;
}

.list-size {
  width: 270px;
}

.checkbox-list-height {
  max-height: 35vh;
  @media (min-height: 800px) {
    max-height: 50vh;
  }
}

.override-mb {
  margin-bottom: 6px !important;
}
</style>
