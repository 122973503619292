<template>
  <div class="border border-gray-600 filter">
    <div v-if="name" class="relative p-1 duration-100 cursor-pointer hover:bg-gray-100" @click="toggleCollapsed">
      <div class="pl-0 text-sm text-gray-800 name" :class="{'pl-3 dot': active}">{{ name }}</div>
      <Icon
        name="chevron-down"
        class="absolute text-gray-600 duration-200 transform -translate-y-1/2 icon top-1/2"
        :class="{'rotate-180': !isCollapsed}"
      />
    </div>
    <transition name="collapse">
      <div v-if="!isCollapsed" class="px-5 overflow-y-auto filter-value-collapse">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * The name displayed in the clickable section.
     */
    name: {
      type: String,
      required: false,
    },
    /**
     * When true a dot is displayed next to the name.
     */
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped lang="scss">
.name {
  cursor: pointer;
  position: relative;
  transition: .1s;
  &:before {
    position: absolute;
    top: 8px;
    left: 1px;
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background-color: #FE5000;
    border-radius: 50%;
    opacity: 0;
    transition: .1s;
  }
  &.dot:before {
    opacity: 1;
  }
}

.icon {
  right: .125rem;
}

.filter-value-collapse {
  max-height: 325px;
  transition: max-height .3s;
}

.collapse-enter, .collapse-leave-to {
  max-height: 0;
}

.collapse-enter-active, .collapse-leave-active{
  overflow: hidden;
}
</style>
