<template>
<div class="datacenter__selected-columns-container">
  <div class="datacenter__selected-columns-main">
    <div class="pl-2 text-gray-800">{{ $tc('list_rows') }}</div>
    <div class="datacenter__selected-columns-items">
      <div class="datacenter__selected-columns-scroll">
        <draggable
          id="selRows"
          v-model="selCols"
          drag-class="datacenter__selected-columns-dragged-item"
          ghost-class="datacenter__selected-columns-dragged-item"
          class="datacenter__selected-columns-draggable"
        >
          <div
            class="datacenter__selected-columns-dnd-item"
            v-for="column in selCols"
            :key="column"
          >
            <div class="flex flex-row items-center">
              {{ $tc(column, 1) }}
              <div class="flex-none transform icon-position">
                <IconLink dark icon-name="close" @click="removeColumn(column)"/>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
  <div class="self-center mx-3">
    <IconLink
      id="plus-button"
      icon-name="plus"
      :loading="loadingColumns"
      :disabled="!isReady"
      @click="showColumnList=!showColumnList"
    />
  </div>
  <ColumnList :show="showColumnList" @clicked-outside="close"/>
</div>
</template>

<script>
import IconLink from '@/ui/components/IconLink.vue';
import ColumnList from './ColumnList.vue';

export default {
  components: { ColumnList, IconLink },
  data() {
    return {
      showColumnList: false,
    };
  },
  computed: {
    selCols: {
      get() {
        return this.$store.getters.getSelCols;
      },
      set(values) {
        this.$store.commit('setSelCols', values);
      },
    },
    availableCols() {
      return this.$store.getters.getAvailableCols;
    },
    isReady() {
      return this.availableCols.length > 1;
    },
    loadingColumns() {
      return this.$store.state.datacenterList.loadingColumns;
    },
  },
  methods: {
    close() {
      if (this.showColumnList) {
        this.showColumnList = false;
      }
    },
    removeColumn(column) {
      this.$store.commit('removeColumn', column);
    },
  },
  watch: {
    selCols() {
      this.$emit('updated-columns');
    },
  },
};
</script>

<style>
  .datacenter__selected-columns-dnd-item {
    @apply p-1 mr-2 text-white whitespace-pre bg-gray-800 cursor-move;
  }
  .datacenter__selected-columns-dragged-item {
    background-color: theme('colors.primary.500');
  }

  .datacenter__selected-columns-scroll {
    @apply w-full pt-0 pl-1 overflow-x-auto;
    touch-action: cross-slide-x !important;
  }

  .datacenter__selected-columns-draggable {
    @apply flex flex-row p-1 pb-2;
  }

  .datacenter__selected-columns-items {
    @apply flex flex-row;
    min-height: 50px;
  }

  .datacenter__selected-columns-main {
    @apply flex flex-col w-full;
    max-width: calc(100% - 48px);
  }

  .datacenter__selected-columns-container {
    @apply flex flex-row;

  }

  .min-height {
    min-height: 80px;
  }
  .icon-position {
    line-height: 100%;
    --transform-translate-y: 0.05rem
  }
</style>
