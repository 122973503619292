<template>
  <div>
    <div class="text-gray-800">
      {{ $t('count') }}
      <span v-if="selPopulation">{{ $tc(selPopulation, 0) }}</span>
    </div>
    <div class="text-3xl text-center text-primary-500">{{ rowCountLocalized }}</div>
  </div>
</template>

<script>
import { localizeNumber } from '@/helper';

export default {
  computed: {
    selPopulation() {
      return this.$store.getters.getSelPopulation;
    },
    rowCountLocalized() {
      return localizeNumber(this.$store.getters.getRowCount);
    },
  },
};
</script>

<style scoped>

</style>
