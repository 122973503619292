<template>
  <div class="bg-white">
    <slot/>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
