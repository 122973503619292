<template>
  <div class="datacenter__empty-state">
    <p class="datacenter__empty-state-title">{{$t('idc_list_empty_state_title')}}</p>
    <ul class="datacenter__empty-state-list">
      <li>{{$t('idc_list_select_database')}}</li>
      <li>{{$t('idc_list_filter')}}</li>
      <li>{{$t('idc_list_cols')}}</li>
      <li>{{$t('idc_list_sort')}}</li>
      <li>{{$t('idc_list_download')}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
};
</script>

<style lang="scss" scoped>
.datacenter__empty-state {
  @apply text-gray-600 mb-10;
  .datacenter__empty-state-title {
    @apply text-4xl font-light mb-3;
  }
  .datacenter__empty-state-list {
    li {
      &:before {
        content: '\25C6';
        @apply inline-block pr-2;
      }
    }
  }
}
</style>
