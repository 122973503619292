<template>
  <FilterElem :name="$tc(filter.type, 1)" :active="hasSelectedValues">
    <div
      class="block text-sm text-right text-gray-300 transition duration-100 pointer-events-none"
      :class="{'pointer-events-auto cursor-pointer text-gray-800 hover:text-primary-500': hasSelectedValues}"
      @click="deselectAll"
    >
      {{ $t('remove_selection') }}
    </div>
    <CheckBox
      v-for="val in filter.values"
      :key="`${filter.type}-${filterValue(val)}`"
      v-model="selectedValues"
      :native-value="filterValue(val)"
      class="checkbox"
      @input="onInput"
    >
    <template v-if="hasLocalization(filter.type)">
      {{ $t(val.value) }}
    </template>
    <template v-else>
      {{ val.value }}
    </template>
    </CheckBox>
  </FilterElem>
</template>

<script>
import FilterElem from './FilterElem.vue';

export default {
  components: { FilterElem },
  data() {
    return {
      selectedValues: [],
    };
  },
  props: {
    /**
     * Value is an array of selected filters in the following form:
     * [
     *   {
     *     id: ...,
     *     values: [...]
     *   },
     *   ...
     * ]
     */
    value: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.selectedValues = this.value.find((f) => f.type === this.filter.type)?.values ?? [];
  },
  computed: {
    hasSelectedValues() {
      return this.selectedValues.length > 0;
    },
  },
  methods: {
    deselectAll() {
      this.selectedValues = [];
      this.$emit('input', { type: this.filter.type, values: [] });
    },
    /**
     * Returns filter-value-id when present otherwise filter-value. Both as strings.
     */
    filterValue(filter) {
      if (filter.valueId !== null) {
        return filter.valueId.toString();
      }
      return filter.value.toString();
    },
    onInput(values) {
      this.$emit('input', { type: this.filter.type, values });
    },
    hasLocalization(filter) {
      return this.$store.getters.getLocalizedFilters.includes(filter);
    },
  },
};
</script>

<style scoped>
.checkbox {
  margin-bottom: .5rem !important;
}
</style>
