<template>
  <div>
    <div class="mb-3 text-gray-800">{{ $t('baseData') }}</div>
    <SelectField2 @change="onChange" class="mb-override" v-model="selPopulation" :options="populations"/>
  </div>
</template>

<script>
export default {
  computed: {
    selPopulation: {
      get() {
        return this.$store.state.datacenterList.selPopulation;
      },
      set(val) {
        this.$store.commit('setSelPopulation', val);
      },
    },
    populations() {
      return this.$store.state.datacenterList.availablePopulations.map((p) => ({ text: this.$tc(p, 0), value: p }));
    },
  },
  methods: {
    onChange() {
      this.$store.dispatch('updateAvailableFilters');
      this.$store.dispatch('getAvailableCols');
    },
  },
};
</script>

<style scoped>

.mb-override {
  margin-bottom: 0px !important;
}

</style>
