<template>
  <div class="relative h-full">
    <div class="p-3 mb-3 text-gray-800">
      {{ $t('filter_2') }}
      <div v-if="loadingFilters" class="datacenter__filters-loading-circle">
        <LoadingCircle />
      </div>
    </div>
    <div
      v-if="filters"
      :class="{ 'opacity-75 pointer-events-none': loadingFilters }"
      class="absolute bottom-0 left-0 right-0 p-3 overflow-y-auto filters"
      ref="filters"
    >
      <div v-for="(filter, i) in filters" :key="filter.type" :id="`filter-${filter.type}`">
        <FilterCheckbox
          v-if="isType(filter, 'CHECKBOX')"
          v-model="selFilters"
          @input="onInput"
          :filter="filter"
          :class="{ 'border-b-0': i !== filters.length - 1 }"
        />
        <FilterDatepicker
          v-else-if="isType(filter, 'DATE')"
          v-model="selFilters"
          @click-input="scrollToElem(filter.type)"
          @input="onInput"
          :filter="filter"
          :class="{ 'border-b-0': i !== filters.length - 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';
import FilterDatepicker from './FilterDatepicker.vue';
import LoadingCircle from '../../../../components/LoadingCircle.vue';

export default {
  components: { LoadingCircle, FilterDatepicker, FilterCheckbox },
  computed: {
    /**
     * Get and set selected filters
     * NOTE: Sets only a filter with empty values when filter is already selected to prevent
     * setting empty filters on mounting via v-model.
     */
    selFilters: {
      get() {
        return this.$store.state.datacenterList.selFilters;
      },
      set(filter) {
        if ((filter.values.length === 0 && this.filterIsSelected(filter)) || filter.values.length > 0) {
          this.$store.commit('updateFilter', filter);
          this.$emit('updated-filters');
        }
      },
    },
    /**
     * Get the selected population as an object.
     * @returns {any}
     */
    selPopulation() {
      return this.$store.getters.getSelPopulation;
    },
    /**
     * Get the filters of the selected population.
     * @returns {any}
     */
    filters() {
      return this.$store.getters.getAvailableFilters;
    },
    loadingFilters() {
      return this.$store.state.datacenterList.loadingFilters;
    },
  },
  methods: {
    isType(filter, type) {
      return filter.selectionType === type;
    },
    onInput() {
      this.$store.dispatch('updateAvailableFilters');
    },
    filterIsSelected(filter) {
      return this.selFilters.filter((e) => e.type === filter.type).length;
    },
    /**
     * Scroll to filter element within the scrollable div.
     * @param filterName
     */
    scrollToElem(filterName) {
      const elem = document.getElementById(`filter-${filterName}`);
      this.$refs.filters.scroll({
        top: elem.offsetTop + 40,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.filters {
  top: 2.25rem;
}

.datacenter__filters-loading-circle {
  @apply absolute transform scale-50;
  --transform-translate-x: 60px;
  --transform-translate-y: -28px;
}
</style>
